import React from 'react';
import './index.css';


import Layout from "../layouts/home/layout";


function App()  {
    return (
      <div className="App">
        <Layout>
          <section className="content_area bg-back/50 flex items-center pl-5 text-xs">
            <div className="grid grid-cols-3">
                <div className="col-span-2">
                  <h1 className="text-red-1000 font-bold text-base">Welcome to MSI</h1>
                  <p className="mt-2">MSI INTERNATIONAL plc is a public company quoted on the London Stock Exchange and comprises three operating divisions.</p>
                  <p className="text-red-1000 font-bold mt-2">Defence <span className="text-white">-</span> <a className="hover:text-gray-1000" href="http://www.msi-dsl.com/" target="_blank">www.msi-dsl.com</a></p>
                  <p>Naval weapons, tactical and navigation equipment</p>
                  <p className="text-red-1000 font-bold">Forging</p>
                  <p>Steel forging for many applications</p>
                  <p className="text-red-1000 font-bold">Petrol Station Superstructures - <a className="hover:text-gray-1000" href="http://www.global-msi.com/" target="_blank">www.global-msi.com</a></p>
                  <p>Canopies - Shops and Car Washes.</p>
                  <ul className="my-3 ml-9">
                    <li>Group sales amount to some £50m per annum</li>
                    <li>Strong balance sheet - supports growth</li>
                    <li>Positive cash flow - supports investment</li>
                    <li>Exports account for some 60% of group sales</li>
                    <li>375 employees</li>
                    <li className="pr-10">Strong commitment to a comprehensive expansion and investment programme</li>
                  </ul>
                  <h3 className="text-[15px] font-bold mt-3">Forging Solutions Transforming Business</h3>
                </div>
              </div>
          </section>
        </Layout>
      </div>
    );
}

export default App;
