import React from 'react';

import Header from '../../components/Header/Header';
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import TopMenu from '../../components/TopMenu/TopMenu';
import MenuImage from '../../components/MenuImage/MenuImage';
import Steel from '../../images/Layout/steel.jpg';

function Layout({children}) {
  return (
      <div className="container mx-auto">
        <Header />
        <div className="grid grid-cols-4">
          <div className="col-span-1">
            <MenuImage />
            <LeftMenu />
            <img src={Steel} alt="Steel" />
          </div>
          <div className="col-span-3">
            <TopMenu />
            {children}
          </div>
        </div>
      </div>
  );
}

export default Layout;
